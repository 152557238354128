exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-legal-notice-js": () => import("./../../../src/pages/legal-notice.js" /* webpackChunkName: "component---src-pages-legal-notice-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-services-conception-js": () => import("./../../../src/pages/services/conception.js" /* webpackChunkName: "component---src-pages-services-conception-js" */),
  "component---src-pages-services-development-js": () => import("./../../../src/pages/services/development.js" /* webpackChunkName: "component---src-pages-services-development-js" */),
  "component---src-pages-services-industrial-engineering-js": () => import("./../../../src/pages/services/industrial-engineering.js" /* webpackChunkName: "component---src-pages-services-industrial-engineering-js" */),
  "component---src-pages-services-infrastructure-js": () => import("./../../../src/pages/services/infrastructure.js" /* webpackChunkName: "component---src-pages-services-infrastructure-js" */),
  "component---src-pages-services-iot-and-digitalisation-js": () => import("./../../../src/pages/services/iot-and-digitalisation.js" /* webpackChunkName: "component---src-pages-services-iot-and-digitalisation-js" */),
  "component---src-pages-services-standards-and-security-js": () => import("./../../../src/pages/services/standards-and-security.js" /* webpackChunkName: "component---src-pages-services-standards-and-security-js" */),
  "component---src-pages-showcases-js": () => import("./../../../src/pages/showcases.js" /* webpackChunkName: "component---src-pages-showcases-js" */),
  "component---src-templates-showcase-contentful-js": () => import("./../../../src/templates/showcase-contentful.js" /* webpackChunkName: "component---src-templates-showcase-contentful-js" */)
}

